import { ApiService } from '@agent-ds/shared/models/api-service';
import { toAsialRequestParameters } from '@agent-ds/shared/util/util';
import { HttpClient } from '@angular/common/http';
import { REPORT_SUCCESS_HEADER } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProgressApiService extends ApiService {
    constructor(http) {
        super();
        this.http = http;
    }
    addProgress(request) {
        return this.http.post('/api/progress', request, { headers: { [REPORT_SUCCESS_HEADER]: 'TRUE' } });
    }
    getList(params) {
        return this.http.get('/api/progress/search', {
            params: toAsialRequestParameters(params),
        });
    }
    updateHistoryRemarks(progressId, revision, remarks) {
        return this.http.put(`/api/progress/${progressId}/history/${revision}`, remarks);
    }
    updateWithAction(progressId, requestObject) {
        return this.http.put(`/api/progress/${progressId}`, requestObject);
    }
    updateWithoutAction(progressId, requestObject) {
        return this.http.put(`/api/progress/${progressId}/no-action`, requestObject);
    }
    delete(progressId) {
        return this.http.delete(`/api/progress/${progressId}`);
    }
    getDetail(progressId) {
        return this.http.get(`/api/progress/${progressId}`);
    }
    revertHistory(progressId, revision, remarks) {
        return this.http.put(`/api/progress/${progressId}/revert/${revision}`, null, { params: { remarks: remarks } });
    }
    bulkRegisterStudent(studentId, jobIds) {
        return this.http.put(`/api/progress/bulk/student/${studentId}/register`, {
            jobIds: jobIds,
        });
    }
    bulkRegisterStudentsByJobId(jobId, studentIds) {
        return this.http.put(`/api/progress/bulk/job/${jobId}/register`, {
            studentIds: studentIds,
        });
    }
    bulkRegisterStudentsByProgressIds(progressIds) {
        return this.http.put('/api/progress/bulk/waiting-student-ok/overwrite', {
            progressIds: progressIds,
        });
    }
    bulkRecommendStudent(studentId, jobIds) {
        return this.http.put(`/api/progress/bulk/student/${studentId}/recommend`, {
            jobIds: jobIds,
        });
    }
    bulkRegisterStudentNoUpdate(studentId, jobIds) {
        return this.http.put(`/api/progress/bulk/student/${studentId}/register/no-update`, {
            jobIds: jobIds,
        });
    }
    bulkDecline(progressIds) {
        return this.http.put('/api/progress/bulk/end', { progressIds: progressIds });
    }
    bulkEdit(progressIds, request) {
        return this.http.put('/api/progress/bulk/edit', {
            progressIds: progressIds,
            fromStatus: request.fromStatus,
            toStatus: request.toStatus,
            stopReason: request.stopReason,
            remarks: request.remarks,
        });
    }
    bulkSettingInterview(request) {
        return this.http.put('/api/progress/bulk/setting-interview', request);
    }
    checkProgress(studentIds, jobIds) {
        return this.http.get('/api/progress/check', {
            params: toAsialRequestParameters({ studentIds: studentIds, jobIds: jobIds }),
        });
    }
    getStakeholders(progressIds) {
        return this.http.get('/api/progress/stakeholders/email', {
            params: toAsialRequestParameters({ progressIds: progressIds }),
        });
    }
    overwrite(progressId, requestObject) {
        return this.http.put(`/api/progress/${progressId}/overwrite`, requestObject);
    }
    getContractInfo(progressId) {
        return this.http.get(`/api/progress/${progressId}/contract-info`);
    }
    getReportRapa(date, teamId) {
        return this.http.get('/api/progress/monthly-kpi/rapa', { params: Object.assign({}, date, { teamId: teamId + '' }) });
    }
    getReportCa(date, teamId) {
        return this.http.get('/api/progress/monthly-kpi/ca', { params: Object.assign({}, date, { teamId: teamId + '' }) });
    }
    ngEnd(progressIds) {
        return this.http.put('/api/progress/bulk/ng-end', { progressIds: progressIds });
    }
    bulkCreate(jobIds, studentIds) {
        return this.http.post('/api/progress/bulk', { jobIds: jobIds, studentIds: studentIds });
    }
    bulkCreateStudent(studentId, jobIds, request) {
        return this.http.post(`/api/progress/bulk/student/${studentId}`, Object.assign({}, request, { jobIds: jobIds }));
    }
    bulkCreateJob(jobId, studentIds, request) {
        return this.http.post(`/api/progress/bulk/job/${jobId}`, Object.assign({}, request, { studentIds: studentIds }));
    }
    bulkCreateCombination(combinations) {
        return this.http.post(`/api/progress/bulk/combination`, {
            studentIdJobIds: combinations,
        });
    }
}
ProgressApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgressApiService_Factory() { return new ProgressApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ProgressApiService, providedIn: "root" });
